












































import { LoginRequest } from "@/models/utils/login-request.interface";
import router from "@/router";
import { authenticate } from "@/services/api/authenticate.service"; 
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators"; 
const authModule = getModule(AuthModule, store); 

@Component
export default class Login extends Vue {
  private valid = true;
  private showAlert = false;
  private alertText: string;
  private showPassword = false;  
  private rememberMe = false;
  private form: LoginRequest = {
    username: "",
    password: "" 
  }

  async login(): Promise<void> { 
    this.showAlert = false;
    try {
      let r = await authenticate.login(this.form);  
      r.data.rememberMe=this.rememberMe; 
      authModule.login(r.data);
      router.push({ path: "/" });
    } catch { 
      this.showAlert = true;
    }
  }
}

import { AuthState } from "@/models/utils/auth-state.interface";
import { LoginRequest } from "@/models/utils/login-request.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "Authenticate";

class AuthenticateService extends ApiService {
  login(credentials: LoginRequest) {
    return this.post<LoginRequest, AuthState>(
      `${CONTROLLER}/login`,
      credentials
    );
  }
}

export const authenticate = new AuthenticateService();
